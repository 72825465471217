.item-enter td {
  opacity: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 0 !important;
}
.item-enter-active td {
  transition: all 0.4s ease-out !important;
}
.item-enter-active td, .item-exit td {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  line-height: 1em !important;
  opacity: 1 !important;
}
.item-exit-active td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 0 !important;
  opacity: 0 !important;
  transition: all 0.4s ease-out !important;
}
.item-exit-active td * {
  height: 0 !important;
}