.react-datepicker__close-icon::after {
  background-color: #747474;
}

.react-datepicker__day--selected {
  background-color: #FCB301;
}

.react-datepicker__day--keyboard-selected {
  color: #000;
  background-color: #FCB301;
}

#datepicker-portal {
  z-index: 2000;
  position: relative;
}

.react-datepicker {
  font-family: "Rubik";
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  text-align: center;
  background-color: #F4F0ED;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #747474;
}
