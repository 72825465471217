.Toastify__toast-container {
  width: 590px !important;
}
/*.Toastify__toast--default {*/
/*}*/
/*.Toastify__toast--info {*/
/*}*/
.Toastify__toast--success {
  background: #7a7a7b !important;
}
.Toastify__toast--warning {
  background:#FFD43D !important;
  font-weight: bold;
}
.Toastify__toast--error {
  background: #FF3F3B !important;
  font-weight: bold;
}
